import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=767393a2&scoped=true"
import script from "./detail.vue?vue&type=script&lang=js"
export * from "./detail.vue?vue&type=script&lang=js"
import style0 from "./detail.vue?vue&type=style&index=0&id=767393a2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../yarn/canteen_h5_customer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767393a2",
  null
  
)

export default component.exports