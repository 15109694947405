<template>
  <div class="page">
    <!--订餐历史-->
    <div class="fixed-top">
      <div v-if="tip" class="messages-box">
        <div class="messages">
          <div class="bell-box">
            <div class="bell">
              <img class="icon" src="../../assets/order_history_ling.png" alt="">
              取消订餐提示
            </div>
            <div class="bell_tab" @click="textStatus = !textStatus">
              {{ textStatus ? '收起' : '显示全部' }}
            </div>
          </div>
          <div :class="['tip', { ellipsis1: !textStatus }]">{{ tip }}</div>
        </div>
      </div>
      <div class="header">
        <div
          class="item"
          :class="tabIndex == 3 ? 'active' : ''"
          @click="onTabClick(3)"
        >
          <div class="header-icon">
            <div class="title">待核销</div>
            <img v-if="tabIndex == 3" src="../../assets/record_header_arrow.png" :class="icon?'asc':'desc'">
          </div>
          <div class="line" />
        </div>
        <div
          class="item"
          :class="tabIndex == 1 ? 'active' : ''"
          @click="onTabClick(1)"
        >
          <div class="header-icon">
            <div class="title">已核销</div>
            <img v-if="tabIndex == 1" src="../../assets/record_header_arrow.png" :class="icon?'desc':'asc'">
          </div>
          <div class="line" />
        </div>
        <div
          class="item"
          :class="tabIndex == 2 ? 'active' : ''"
          @click="onTabClick(2)"
        >
          <div class="header-icon">
            <div class="title">已取消</div>
            <img v-if="tabIndex == 2" src="../../assets/record_header_arrow.png" :class="icon?'desc':'asc'">
          </div>
          <div class="line" />
        </div>
      </div>
    </div>
    <div class="container">
      <BaseList ref="list" class="content" :get-list-request="getListRequest">
        <template v-slot:default="listData">
          <div
            v-for="(item, index) in listData.listData"
            :key="index"
            class="content"
          >
            <div
              class="content-top"
              :style="{ background: item.label.bg_color }"
            >
              <div
                class="content-top-left"
                :style="{ color: item.label.color }"
              >
                {{ item.label.name }}
              </div>
              <div class="content-top-right">
                <div
                  style="font-size: 12px"
                  :style="{ color: item.label.color }"
                >
                  {{ item.date_format }}
                </div>
                <div
                  style="margin-top: 5px; font-size: 10px"
                  :style="{ color: item.label.color }"
                >
                  周{{ item.date_week_format }}
                </div>
              </div>
            </div>
            <div
              v-for="(sub_item, sub_index) in item.detail"
              :key="sub_index"
              class="content-mid"
            >
              <div class="content-mid-line" />
              <div class="content-mid-top">
                <div class="content-mid-left">
                  <img class="content-mid-img" :src="sub_item.image_path">
                  <div class="dishes-detail">
                    <div class="dishes-detail-name">{{ sub_item.name }}</div>
                    <div class="dishes-detail-ad ellipsis">
                      {{ sub_item.dishes.ad_words }}
                    </div>
                    <!-- <div wx:if="{{sub_item.dishes.ad_words==''}}" class="dishes-detail-ad">大厨很忙没有介绍...</div> -->
                    <div class="dishes-detail-quantity">
                      x{{ sub_item.quantity }}
                    </div>
                  </div>
                </div>
                <div class="dishes-money">
                  ¥{{ sub_item.subtotal_price_format }}
                </div>
              </div>
            </div>
            <div class="content-bottom">
              <div class="content-bottom-first">
                <div v-html="item.status_html_format" />
                <div class="content-bottom-first-total">
                  ¥{{ item.total_price_format }}
                </div>
              </div>
              <div>{{ item.order_no }}</div>
              <div
                v-if="item.user_remark"
                class="showremark"
                @click="Putaway(index)"
              >
                <div
                  :class="
                    currentIndex == index && away
                      ? 'open'
                      : 'close'
                        ? 'clip'
                        : ''
                  "
                >
                  订单备注：{{ item.user_remark }}
                </div>
                <div
                  v-if="GetBLen(item.user_remark) > 33"
                  class="remark-detail"
                >
                  {{ currentIndex == index && away ? " 收起" : "查看" }}
                </div>
              </div>
              <div
                v-if="item.check_remark"
                class="showremark"
                @click="Checkaway(index)"
              >
                <div
                  :class="
                    checkIndex == index && checkaway
                      ? 'open'
                      : 'close'
                        ? 'clip'
                        : ''
                  "
                >
                  审批备注：{{ item.check_remark }}
                </div>
                <div
                  v-if="GetBLen(item.check_remark) > 33"
                  class="remark-detail"
                >
                  {{ checkIndex == index && checkaway ? "收起" : "查看" }}
                </div>
              </div>
              <div>{{ item.company.name }}</div>
              <div class="content-bottom-third">
                <div>{{ item.created_at_format }}</div>
                <button
                  v-if="tabIndex == 3 && item.is_allow_cancel != 0"
                  @click="showDialogBtn(item.id)"
                >
                  取消
                </button>
                <div v-if="item.status==30 && item.evaluation_status==10 && evaluationSetting" class="evaluate" @click="toEvaluateOrder(item.id)">待评价</div>
                <div v-if="item.status==30 && item.evaluation_status==20 && evaluationSetting" class="evaluate complete" @click="toEvaluateDetail(item.id)">查看评价</div>
              </div>
            </div>
          </div>
        </template>
      </BaseList>
    </div>
  </div>
</template>
<script>
import { getHistory as getList, cancel, getDishesTip } from '@/api/meal'
import BaseList from '@/components/BaseList'
import { Dialog } from 'vant'

export default {
  name: 'History',
  components: { BaseList },
  data() {
    return {
      tabIndex: 3,
      // 加载中
      loading: false,
      typeList: [],
      typeIndex: 0,
      away: true,
      checkaway: true,
      currentIndex: -1,
      checkIndex: -1,
      status_more: [19, 20, 39],
      // 控制箭头
      icon: true,
      order: 'asc',
      evaluationSetting: false,
      tip: '温馨提示：暂不支持就餐前一天内更改预定',
      // 展开状态
      textStatus: false
    }
  },
  created() {
    this.evaluationSetting = this.$store.getters.evaluationSetting
    this.tabIndex = this.$store.getters.historyTabIndex || 3
    this.icon = this.$store.getters.historyIcon
    console.log(222, this.icon)
    if (this.tabIndex == 1) {
      this.order = this.icon ? 'desc' : 'asc'
      this.status_more = [30]
    } else if (this.tabIndex == 2) {
      this.order = this.icon ? 'desc' : 'asc'
      this.status_more = [40]
    } else if (this.tabIndex == 3) {
      this.status_more = [19, 20, 39]
      this.order = this.icon ? 'asc' : 'desc'
    }
    this.getDishesTip()
  },
  methods: {
    getDishesTip() {
      getDishesTip({ type: 'cancel' })
        .then((res) => {
          this.tip = res.data.message
        })
        .catch((error) => {
          this.$dialog.alert({ message: '获取订餐提示失败：' + (error.msg || error) })
        })
    },
    onTabClick(index) {
      if (this.tabIndex === index) {
        this.icon = !this.icon
      } else {
        this.icon = true
      }
      this.tabIndex = index
      this.$store.dispatch('save/CacheData', { historyTabIndex: index })
      this.$store.dispatch('save/CacheData', { historyIcon: this.icon })
      if (this.tabIndex == 1) {
        this.order = this.icon ? 'desc' : 'asc'
        this.status_more = [30]
      } else if (this.tabIndex == 2) {
        this.order = this.icon ? 'desc' : 'asc'
        this.status_more = [40]
      } else if (this.tabIndex == 3) {
        this.status_more = [19, 20, 39]
        this.order = this.icon ? 'asc' : 'desc'
      }
      this.$refs.list.onRefresh()
    },
    getListRequest(param) {
      param.field = 'date'
      param.order = this.order
      param.status_more = this.status_more
      return getList(param)
    },
    // 订单备注
    Putaway(index) {
      if (index != this.currentIndex) {
        this.currentIndex = index
        this.away = true
      } else {
        this.currentIndex = -1
        this.away = false
      }
    },
    // 审批备注
    Checkaway(index) {
      if (index != this.checkIndex) {
        this.checkIndex = index
        this.checkaway = true
      } else {
        this.checkIndex = -1
        this.checkaway = false
      }
    },
    // 使用正则替换所有中文字符,然后再计算
    GetBLen: function(str) {
      if (str == null) return 0
      if (typeof str != 'string') {
        str += ''
      }
      return str.replace(/[^\x00-\xff]/g, 'ab').length
    },
    /**
   * 取消预定
   */
    showDialogBtn(e) {
      const id = e
      const param = {}
      param.id = id
      Dialog.confirm({ title: '温馨提示', message: '确定取消此订单吗？' }).then(() => {
        this.$store.state.isLoading = true
        cancel(param).then(res => {
          this.$store.state.isLoading = false
          this.$refs.list.onRefresh()
        }).catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {
            this.$refs.list.onRefresh()
          })
        })
      }).catch(() => {})
    },
    // 跳转评价页面
    toEvaluateOrder(id) {
      this.$router.push({ name: 'Evaluate', query: { id: id, orderType: 1 }})
    },
    toEvaluateDetail(id) {
      this.$router.push({ name: 'EvaluateDetail', query: { id: id, orderType: 1 }})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./history.less";
</style>
