import { render, staticRenderFns } from "./dishes-warn.vue?vue&type=template&id=53137d54&scoped=true"
import script from "./dishes-warn.vue?vue&type=script&lang=js"
export * from "./dishes-warn.vue?vue&type=script&lang=js"
import style0 from "./dishes-warn.vue?vue&type=style&index=0&id=53137d54&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../yarn/canteen_h5_customer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53137d54",
  null
  
)

export default component.exports