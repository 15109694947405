import { render, staticRenderFns } from "./account_out.vue?vue&type=template&id=0da2db51&scoped=true"
import script from "./account_out.vue?vue&type=script&lang=js"
export * from "./account_out.vue?vue&type=script&lang=js"
import style0 from "./account_out.vue?vue&type=style&index=0&id=0da2db51&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../yarn/canteen_h5_customer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da2db51",
  null
  
)

export default component.exports